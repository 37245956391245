<script>
  import CourseCatalogue from '@/components/resource_library/course_package/edit/course_catalogue'
  import CourseInfo from '@/components/resource_library/course_package/edit/course_info'
  export default {
    components: {
      CourseCatalogue,
      CourseInfo,
    },
    mounted() {
      console.log('ceshi')
    },
    beforeDestroy() {
    },
    methods: {
      getInfoData () {
        const {
          type,
          videofile,
          docfile,
          webpic,
          content,
          title,
        } = this.$refs.info
        return {
          type,
          videofile,
          docfile,
          webpic,
          content,
          title,
        }
      },
      automaticDetection () {
        this.$emit('automaticDetection')
      }
    }
  }
</script>

<template>
  <div
    class="c_rl_course_package_edit_main_wrapper d-flex"
  >
    <CourseInfo
      class="info_group"
      ref="info"
      @automaticDetection="automaticDetection"
    ></CourseInfo>
    <CourseCatalogue
      class="catalogue_group flex-shrink-0"
      @automaticDetection="automaticDetection"
    ></CourseCatalogue>
  </div>
</template>

<style scoped lang="scss">
.c_rl_course_package_edit_main_wrapper {
  .info_group {
    width: 100%;

  }
  .catalogue_group {
    width: 400px;
  }
}
</style>
