<script>
import TaskInfo from '@/models/task/task_info'
import Task from '@/components/resource_library/course_package/choice_task/task'
export default {
  components: {
    Task
  },
  props: {
    taskList: {
      type: Array,
      default: () => {
        return []
      }
    },
    moreTask: {
      type: Boolean,
      default: false
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    all: {
      type: Boolean,
      default: false,
    },
    showSelected: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    loadMoreTaskList () {
      this.$emit('loadMoreTaskList')
    },
    selectTask (taskInfo) {
      this.$emit('selectTask', taskInfo)
    },
    touchAll () {
      this.$emit('touchAll')
    }
  }
}
</script>

<template>
  <div
    class="task_list d-flex flex-column"
  >
    <div
      class="all_group d-flex align-center flex-shrink-0"
    >
      <div
        class="d-flex align-center cp"
        @click.stop="touchAll"
      >
        <div
          class="select cp"
          :class="{
          active: all
        }"
        ></div>
        <div
          class="text cp"
        >
          全选
        </div>
      </div>
    </div>
    <div
      class="scroll_group d-flex align-center flex-wrap"
    >
      <template
        v-if="!showSelected"
      >
        <template
          v-for="(item, index) in taskList"
        >
          <Task
            class="task cp un_sel"
            :key="index"
            :task-info="item"
            :select-list="selectList"
            @click.native.stop="selectTask(item)"
          ></Task>
        </template>
      </template>
      <template
        v-if="showSelected"
      >
        <template
          v-for="(item, index) in selectList"
        >
          <Task
            class="task cp un_sel"
            :key="index"
            :task-info="item"
            :select-list="selectList"
            @click.native.stop="selectTask(item)"
          ></Task>
        </template>
      </template>

    </div>
    <div
      v-show="moreTask"
      class="more_group d-flex align-center justify-center flex-shrink-0"
    >
      <div
        class="more"
        @click.stop="loadMoreTaskList"
      >
        加载更多
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.task_list {
  @include scrollbar;
  width: 100%;
  height: 100%;
  .all_group {
    width: 100%;
    padding-bottom: 10px;
    padding-left: 1px;
    .select {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url(~@/assets/images/course_package/select.png);
      &.active {
        background-image: url(~@/assets/images/course_package/selected.png);
      }
    }
    .text {
      color: #666;
    }
  }
  .scroll_group {
    @include scrollbar;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .task {
    margin: {
      top: 7px;
      left: 7px;
      right: 10px;
      bottom: 10px;
    }
  }
  .more_group {
    width: 100%;
    padding-top: 10px;
    .more {
      font-size: 13px;
      color: $main_blue;
      cursor: pointer;
    }
  }
}
</style>
