<script>
import MainWrapper from '@/components/resource_library/course_package/edit/main_wrapper'
import request from '@/api/request'
import post from '@/utils/axios/post'
import store from '@/store'
import CoursePackage from '@/models/cource_package/index'
import JsModal from '@/components/JsModal'
import { mapState } from 'vuex'
export default {
  components: {
    MainWrapper,
    JsModal,
  },
  data () {
    return {
      // coursePackage: undefined
      loading: false,
      auditable: false,
    }
  },
  computed: {
    // courseId () {
    //   return store.state.course_package.id
    // },
    ...mapState({
      courseId: state => state.course_package.id,
      coursePackage: state => state.course_package.data,
      coursePackageList: state => state.course_package.packageList,
    }),
    statusType () {
      const {
        checkstatus
      } = this.coursePackage
      if (checkstatus === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return 'audit'
      }
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    statusText () {
      const {
        checkstatus
      } = this.coursePackage
      if (checkstatus === 1) {
        return '未审核'
      }
      if (checkstatus === 2) {
        return '待审核'
      }
      if (checkstatus === 3) {
        return '审核通过'
      }
      if (checkstatus === 4) {
        return '审核不通过'
      }
      return ''
    },
    notEditable () {
      const { coursePackage } = this
      if (coursePackage && Number(coursePackage.checkstatus) === 2) {
        return true
      }
      return false
    },
  },
  mounted () {
    // console.log(store.state.course_package)
    this.init()
    this.automaticDetection()
  },
  methods: {
    /**
     * 自动检测是否可以提交
     */
    automaticDetection () {
      const infoData = this.getInfoData()
      if (!infoData) {
      this.auditable = false
        return false
      }
      if (infoData.title.trim() === '') {
      this.auditable = false
        return false
      }
      if (infoData.content.trim() === '') {
      this.auditable = false
        return false
      }
      if (infoData.type === '') {
      this.auditable = false
        return false
      }
      let tasks = []
      const {
        coursePackage
      } = this
      const {
        chapters
      } = coursePackage
      if (!chapters || chapters.length === 0) {
      this.auditable = false
        return false
      }
      for (let i in chapters) {
        tasks = [
          ...tasks,
          ...chapters[i].tasks
        ]
      }
      if (tasks.length === 0) {
      this.auditable = false
        return false
      }
      this.auditable = true
      return true
    },
    async init () {
      // 清空选择的章节
      store.commit(
        'setCoursePackageSection',
        undefined
      )
      // const { id } = store.state.course_package
      if (this.coursePackage && this.coursePackage.id) {
        // 获取数据
        await this.getCoursePackage()
      } else {
        const coursePackage = new CoursePackage()
        store.commit(
          'setCoursePackageData',
          coursePackage
        )
        // this.$set(
        //   this,
        //   'coursePackage',
        //   coursePackage
        // )
      }
    },
    async getCoursePackage () {
      this.loading = true
      const res = await request.request(
        '/resource/queryCource',
        {
          courceId: this.coursePackage.id
        }
      )
      this.loading = false
      if (res.message === 'success') {
        this.coursePackage.updateData(res.contents.PCource)
        // let coursePackage = new CoursePackage(res.contents.PCource)
        // store.commit('setCoursePackageData', coursePackage)
      }
    },
    async doAudit () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      const { coursePackage, coursePackageList } = this
      const infoData = this.getInfoData()
      const canSave = this.checkSavePermission(infoData)
      if (!canSave) {
        return
      }
      this.getSectionData() // 更新章节数据
      this.loading = true
      if (!coursePackage.id) {
        await coursePackage.create(infoData)
      } else {
        await coursePackage.updateInfo(infoData)
      }
      await coursePackage.updateSection()
      await coursePackage.submit()
      this.loading = false
      if (coursePackageList && coursePackageList.length) {
        // const aim = coursePackageList.find((unit) => {
        //   return unit.id === coursePackage.id
        // })
        // console.log(aim, coursePackageList, coursePackage)
        // if (aim) {
        //   aim.checkstatus = 2
        // }
      }
      this.$notice({
        desc: '提交成功',
        type: 'success'
      })
      this.$router.push({
        name: 'fun_template_draft',
        params: {
          type: 'course'
        }
      })
      this.$modal.hide('globalCoursePackageEdit')
    },
    async doSave () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      const { coursePackage, coursePackageList } = this
      const infoData = this.getInfoData()
      console.log(infoData)
      if (infoData.title.trim() === '') {
        this.$notice({
          desc: '请输入标题'
        })
        return false
      }
      // const canSave = this.checkSavePermission(infoData)
      // if (!canSave) {
      //   return
      // }
      this.getSectionData() // 更新章节数据
      if (coursePackage.id) {
        this.loading = true
        const infoRes = await coursePackage.updateInfo(infoData)
        this.loading = false
        if (infoRes) {
          this.loading = true
          const sectionRes = await coursePackage.updateSection()
          this.loading = false
          console.log(infoRes, sectionRes)
        }
        const index = coursePackageList.findIndex((unit) => {
          return unit.id === coursePackage.id
        })
        if (index !== -1) {
          coursePackageList.splice(index, 1, coursePackage)
          this.$notice({
            desc: '保存成功, 请前往草稿箱查看',
            type: 'success'
          })
        }

      } else {
        this.loading = true
        const infoRes = await coursePackage.create(infoData)
        this.loading = false
        if (infoRes) {
          this.loading = true
          await coursePackage.updateSection()
          this.loading = false
        }
        coursePackageList.unshift(coursePackage)
        this.$notice({
          desc: '保存成功, 请前往草稿箱查看',
          type: 'success'
        })
      }


    },
    getInfoData () {
      const { coursePackage } = this
      // console.log(this.$refs.main)
      if (!this.$refs.main) {
        return false
      }
      const data = this.$refs.main.getInfoData()
      return data
      // 判断是否有id
      // 如无id 则创建
      // 有id
      // 更新章节
    },
    getSectionData () {
      const { coursePackage } = this
      console.log(coursePackage)
      const {
        chapters
      } = coursePackage
      if (chapters && chapters.length) {
        for (let i in chapters) {
          chapters[i].taskids = chapters[i].tasks.map((item) => {
            return Number(item.id)
          })
        }
      }
      console.log(chapters)
    },
    /**
     * 检测保存权限
     */
    checkSavePermission (infoData) {
      console.log(infoData)
      if (infoData.title.trim() === '') {
        this.$notice({
          desc: '请输入标题'
        })
        return false
      }
      if (infoData.content.trim() === '') {
        this.$notice({
          desc: '请输入简介'
        })
        return false
      }
      if (infoData.type === '') {
        this.$notice({
          desc: '请选择类型'
        })
        return false
      }
      let tasks = []
      const {
        coursePackage
      } = this
      const {
        chapters
      } = coursePackage
      if (!chapters || chapters.length === 0) {
        this.$notice({
          desc: '请添加模板'
        })
        return false
      }
      for (let i in chapters) {
        tasks = [
          ...tasks,
          ...chapters[i].tasks
        ]
      }
      if (tasks.length === 0) {
        this.$notice({
          desc: '请添加模板'
        })
        return false
      }
      return true
    }
  }
}
</script>

<template>
  <JsModal
    v-loading="loading"
    :title="'新建课程'"
    @closeHandler="$modal.hide('globalCoursePackageEdit')"
  >
    <div
      v-if="coursePackage"
      class="c_rl_course_packege_index d-flex flex-column bbox_wrapper"
    >
      <!--<div-->
      <!--  class="title_group flex-shrink-0"-->
      <!--&gt;-->
      <!--  <div-->
      <!--    class="title"-->
      <!--  >-->
      <!--    新建课程-->
      <!--  </div>-->
      <!--  <div-->
      <!--    class="close"-->
      <!--  ></div>-->
      <!--</div>-->
      <MainWrapper
        class="main_wrapper"
        ref="main"
        @automaticDetection="automaticDetection"
      ></MainWrapper>
      <div
        class="fun_group flex-shrink-0 d-flex align-center justify-space-between"
      >
        <div
          class="d-flex align-center"
        >
          <div
            class="btn save"
            @click.stop="doSave"
          >
            保存草稿
          </div>
          <div
            class="btn audit btn_dark"
            :class="{
              unable: !auditable
            }"
            @click.stop="doAudit"
          >
            提交审核
          </div>
        </div>
        <div
          v-if="coursePackage.checkstatus !== 1 && coursePackage.checkstatus !== 4"
          class="check_status_group d-flex align-center"
          :class="{
            [statusType]: true
          }"
        >
          <div
            class="icon"
          ></div>
          <div
            class="text"
          >
            {{ statusText }}
          </div>
        </div>
        <el-popover
          v-if="coursePackage.checkstatus === 4"
          placement="top"
          width="200"
          trigger="hover"
        >
          <div
            class="c_rl_course_package_edit_reason_group"
          >
            <div
              class="title"
            >
              未通过原因:
            </div>
            <div
              class="reason"
            >
              {{ coursePackage.reason }}
            </div>
            <div
              class="check_user"
            >
              审核人: {{ coursePackage.checkUserName }}
            </div>
          </div>
          <template  slot="reference">
            <div
              class="check_status_group d-flex align-center"
              :class="{
                [statusType]: true
              }"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                {{ statusText }}
              </div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </JsModal>
</template>

<style scoped lang="scss">
.c_rl_course_packege_index {
  overflow: auto;
  width: 100%;
  height: 100%;
  .main_wrapper {
    @include scrollbar;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .fun_group {
    padding: 10px 20px;
    width: 100%;
    .btn {
      cursor: pointer;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      padding: 0 20px;
      margin-right: 10px;
      line-height: 40px;
      color: #fff;
      &.unable {
        background-color: #ccc;
      }
      &.save {
        background: #399786;
      }
    }
    .check_status_group {
      &.fail {
        color: #D82E0D;
        .text {
          color: #D82E0D;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_fail.png);
        }
      }
      &.audit {
        color: #F78900;
        .text {
          color: #F78900;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_audit.png);
        }
      }
      &.approve {
        color: #05AD00;
        .text {
          color: #05AD00;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_approve.png);
        }
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      .text {
        font-weight: bold;
        font-size: 20px;
        color: #F78900;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
      }
    }
  }
}
</style>
<style lang="scss">
.c_rl_course_package_edit_reason_group {
  font-size: 13px;
  .title {
    color: #999;
    padding-bottom: 10px;
  }
  .reason {
    padding-bottom: 10px;
  }
  .check_user {
    color: #999;

  }
}
</style>
