var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "task_list d-flex flex-column" }, [
    _c("div", { staticClass: "all_group d-flex align-center flex-shrink-0" }, [
      _c(
        "div",
        {
          staticClass: "d-flex align-center cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchAll.apply(null, arguments)
            },
          },
        },
        [
          _c("div", {
            staticClass: "select cp",
            class: {
              active: _vm.all,
            },
          }),
          _c("div", { staticClass: "text cp" }, [
            _vm._v("\n        全选\n      "),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "scroll_group d-flex align-center flex-wrap" },
      [
        !_vm.showSelected
          ? [
              _vm._l(_vm.taskList, function (item, index) {
                return [
                  _c("Task", {
                    key: index,
                    staticClass: "task cp un_sel",
                    attrs: { "task-info": item, "select-list": _vm.selectList },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectTask(item)
                      },
                    },
                  }),
                ]
              }),
            ]
          : _vm._e(),
        _vm.showSelected
          ? [
              _vm._l(_vm.selectList, function (item, index) {
                return [
                  _c("Task", {
                    key: index,
                    staticClass: "task cp un_sel",
                    attrs: { "task-info": item, "select-list": _vm.selectList },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectTask(item)
                      },
                    },
                  }),
                ]
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.moreTask,
            expression: "moreTask",
          },
        ],
        staticClass:
          "more_group d-flex align-center justify-center flex-shrink-0",
      },
      [
        _c(
          "div",
          {
            staticClass: "more",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.loadMoreTaskList.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      加载更多\n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }