<script>
import { mapState } from 'vuex'
import request from '@/api/request'

export default {
  computed: {
    ...mapState({
      coursePackage: state => state.course_package.data,
      taskInfo: state => state.task.info
    }),
  },
  data () {
    return {
      reason: '',
      loading: false,
    }
  },
  methods: {
    doClose () {
      this.$modal.hide('globalCoursePackageFailReason')
    },
    async doSure () {
      const {
        taskInfo
      } = this
      if (this.loading) {
        return
      }
      if (this.reason.trim() === '') {
        this.$notice({
          desc: '请输入未通过原因',
          type: 'error'
        })
        return
      }
      this.loading = true
      let res
      if (taskInfo) {
        // 任务 未通过
        res = await request.request(
          '/resource/checkCource',
          {
            type: 2, // 任务
            id: taskInfo.id,
            checkStatus: 4,
            reason: this.reason
          }
        )
      } else {
        // 课程包未通过
        res = await this.coursePackage.fail({
          reason: this.reason
        })
      }
      this.loading = false
      if (res) {
        this.$notice({
          desc: '提交成功',
          type: 'success'
        })
        this.doClose()
        if (taskInfo) {
          this.$api.moduleTask.closeTask()
        } else {
          this.$modal.hide('globalCheckCrousePackage')
        }
      }
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="fail_reason bbox_wrapper d-flex flex-column align-center"
>
  <textarea
    v-model="reason"
    class="reason"
    placeholder="请给出未通过原因"
  ></textarea>
  <div
    class="fun_group d-flex align-center justify-space-between"
  >
    <div
      class="btn btn_dark"
      @click.stop="doSure"
    >
      确认
    </div>
    <div
      class="btn btn_light"
      @click.stop="doClose"
    >
      取消
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.fail_reason {
  .reason {
    width: 278px;
    height: 140px;
    background: #FBFBFB;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
    margin-top: 20px;
    outline: none;
    padding: 10px 20px;
    color: #333;
    font-family: 'PingFang SC', 'MicroSoft YaHei', serif;
    line-height: 24px;
    font-size: 14px;
    resize: none;
  }
  .fun_group {
    padding: 20px 0;
    width: 278px;
    .btn {
      width: 130px;
      line-height: 40px;
      border-radius: 4px;
      text-align: center;
    }
  }
}
</style>
