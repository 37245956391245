<script>
import draggable from 'vuedraggable'
import CatalogueSection from '@/components/resource_library/course_package/edit/catalogue/section'
import CourseSection from '@/models/cource_package/course_section'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  components: {
    draggable,
    CatalogueSection,
  },
  data () {
    return {
      // list: []
    }
  },
  watch: {
    'coursePackage.chapters': function () {
      this.$emit('automaticDetection')
    },
    'coursePackage.chapters.tasks': function () {
      this.$emit('automaticDetection')
    }
  },
  computed: {
    ...mapState({
      // courseId: state => state.course_package.id,
      coursePackage: state => state.course_package.data,
    }),
    list () {
      if (this.coursePackage) {
        const {
          chapters
        } = this.coursePackage
        if (chapters && chapters.length) {
          // this.list = [
          //   ...chapters
          // ]
          return chapters
        }
      }
      return []
    },
    notEditable () {
      const { coursePackage } = this
      if (coursePackage && Number(coursePackage.checkstatus) === 2) {
        return true
      }
      return false
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    touchAddTask () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      this.$modal.show('globalCoursePackageChoiceTask')
    },
    touchAddSection () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      const courseSection = new CourseSection()
      this.coursePackage.chapters.push(courseSection)
      console.log(this.list)
      store.commit(
        'setCoursePackageSection',
        courseSection
      )
      this.$nextTick(function () {
        this.scrollToBottom()
      })
    },
    changeLog () {

    },
    init () {
      // if (this.coursePackage) {
      //   const {
      //     chapters
      //   } = this.coursePackage
      //   console.log(this.coursePackage)
      //   if (chapters && chapters.length) {
      //     this.list = [
      //       ...chapters
      //     ]
      //   }
      // }
    },
    scrollToBottom () {
      console.log(this.$refs.catalogue_group.$el)
      const catalogue_group = this.$refs.catalogue_group.$el
      catalogue_group.scrollTop = catalogue_group.scrollHeight - catalogue_group.clientHeight
      // catalogue_group.scrollTop = 9999999
    },
    delSection (params) {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      const {
        section
      } = params
      const index = this.list.findIndex((unit) => {
        return unit.addNumber === section.addNumber
      })
      if (index !== -1) {
        this.list.splice(index, 1)
      }
      store.commit(
        'setCoursePackageSection',
        undefined
      )
    }
  }
}
</script>

<template>
<div
  class="course_catalogue d-flex align-center flex-column bbox_wrapper"
>
  <div
    class="fixed_group flex-shrink-0 justify-start align-start flex-column"
  >
    <div
      class="name_group d-flex align-center"
    >
      <div
        class="symbol"
      >
        *
      </div>
      <div
        class="name"
      >
        目录
      </div>
    </div>
    <div
      class="fun_group d-flex align-center"
    >
      <div
        class="btn"
        @click.stop="touchAddSection"
      >
        <div
          class="icon section"
        ></div>
        <div
          class="name"
        >
          增加章节
        </div>
      </div>
      <div
        class="btn"
        @click.stop="touchAddTask"
      >
        <div
          class="icon task"
        ></div>
        <div
          class="name"
        >
          增加模板
        </div>
      </div>
    </div>
  </div>
  <div
    class="catalogue_wrapper"
  >
    <div
      class="catalogue_group"

    >
      <draggable
        v-if="list"
        class="draggable_wrapper"
        :list="list"
        :group="{ name:`course_section`, pull: false, put: true }"
        handle=".c_rl_course_package_edit_catalogue_section"
        animation="300"
        @change="changeLog"
        ref="catalogue_group"
      >
        <template
          v-for="(item) in list"
        >
          <CatalogueSection
            :key="item.addNumber"
            class="catalogue_section"
            :course-section="item"
            @delSection="delSection"
          ></CatalogueSection>
        </template>
      </draggable>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.course_catalogue {
  @include scrollbar;
  width: 400px;
  height: 100%;
  overflow: auto;
  color: #333;
  border-left: 1px solid #f0f0f0;
  .fixed_group {
    width: 100%;
    padding: 20px;
    .name_group {
      padding-bottom: 20px;
      .symbol {
        color: #E12F2F;
        margin-right: 5px;
      }
      .name {
        font-size: 16px;
        color: #333333;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }
    .fun_group {
      .btn {
        @include transition;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #D7D9DD;
        padding: 0 20px;
        line-height: 36px;
        &:hover {
          border-color: $main_blue;
          color: $main_blue;
          .icon {
            &.section {
              background-image: url(~@/assets/images/course_package/add_section_on.png);
            }
            &.task {
              background-image: url(~@/assets/images/course_package/add_task_on.png);
            }
          }
          .name {
            color: $main_blue;
          }
        }
        .icon {
          @include transition;
          @include background;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          &.section {
            background-image: url(~@/assets/images/course_package/add_section.png);
          }
          &.task {
            background-image: url(~@/assets/images/course_package/add_task.png);
          }
        }
        .name {
          @include transition;
          font-size: 14px;
          color: #333333;
          text-align: left;
          font-style: normal;
          line-height: 36px;
        }
      }
    }
  }
  .catalogue_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    .catalogue_group {
      width: 100%;
      height: 100%;
      overflow: auto;
      .draggable_wrapper{
        @include scrollbar;
        overflow-y: auto;
        width: 100%;
        max-height: 100%;
        flex: 1;
        .catalogue_section {
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }
}
</style>
